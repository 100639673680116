<template>
  <info-bar
    facebook="https://www.facebook.com/faamfysiotherapie/"
    instagram="https://www.instagram.com/faamfysiotherapie/"
    youtube="https://www.youtube.com/channel/UCWXUFTFDRpIy-MdRlYuurLw"
    whatsapp="https://wa.me/31629490012"
    mail="info@faamfysiotherapie.nl"
    phone="0413-783357"
  />
  <header>
    <a href="/">
      <p class="logo logo-big masterplan">
        <span>Faam&nbsp;</span>
        <span>Fysiotherapie</span>
      </p>
      <!--      <img-->
      <!--        src="/assets/img/logo/FAAMFysiotherapieLogo.png"-->
      <!--        alt="Logo"-->
      <!--        class="logo logo-big"-->
      <!--      />-->
      <img
        src="/assets/img/logo/FF-262x300.png"
        alt="Logo"
        class="logo logo-small"
      />
    </a>
    <span class="mdi mdi-menu menu-icon" @click="toggleMenu"></span>
    <Menu></Menu>
  </header>
</template>

<script>
import InfoBar from "@/components/InfoBar.vue";
import Menu from "@/components/menu/Menu.vue";

export default {
  name: "Top",
  components: {
    InfoBar,
    Menu,
  },
  mounted() {
    window.onscroll = function () {
      let header = document.getElementsByTagName("header")[0];
      let firstSection = document.getElementsByTagName("section")[1];
      let section_offsets = firstSection.getBoundingClientRect();
      if (section_offsets.top <= 200) {
        header.style.backgroundColor = "#4e4d4d";
        header.querySelector("img").style.maxHeight = "30px";
      } else {
        header.style.backgroundColor = "unset";
        header.querySelector("img").style.maxHeight = "50px";
      }
    };
  },
  methods: {
    toggleMenu() {
      this.$store.commit("menu/toggle");
    },
  },
};
</script>

<style scoped lang="less">
header {
  position: sticky;
  max-width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 99;
  padding: 20px;
  transition-duration: 0.7s;

  .logo {
    //padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    max-height: 50px;
    transition-duration: 0.7s;
    &.logo-small {
      display: none;
    }
    &.logo-big {
      font-size: 50px;
      margin: 0;
      span:nth-child(1) {
        color: var(--primary-color);
        transition-duration: 0.7s;
      }
      span:nth-child(2) {
        color: #ffffff;
        transition-duration: 0.7s;
      }
      &:hover {
        span:nth-child(1) {
          color: #ffffff;
        }
        span:nth-child(2) {
          color: var(--primary-color);
        }
      }
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 30px;

    &:hover {
      color: var(--primary-color);
    }
  }

  nav {
    flex: 1;
    text-align: right;
    padding-right: 20px;

    a {
      font-weight: bold;
      color: #ffffff;
      text-decoration: none;
      padding: 5px;

      &.router-link-exact-active,
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

/**
  * Mobile
  * Tablet
 */
@media screen and (max-width: 1024px) {
  header {
    align-content: flex-end;

    .menu-icon {
      display: unset;
    }
    .menu {
      position: fixed;
      display: flex;
      height: 100vh;
      width: 100%;

      .spacer {
        width: 25%;
      }
    }
  }
}

/**
  * Mobile
 */
@media screen and (max-width: 768px) {
  header {
    .logo {
      &.logo-big {
        display: none;
      }
      &.logo-small {
        display: unset;
      }
    }
  }
}
</style>

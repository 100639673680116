<template>
  <div
    class="trustoo-widget"
    :data-id="trustooId"
    :data-country-code="countryCode"
    :data-badge="badge"
    :data-quote="quote"
    :data-size="size"
    :data-type="type"
    :data-border="border"
    :data-theme="theme"
    :data-background="background"
    :data-google="google"
  ></div>
</template>

<script>
export default {
  name: "Trustoo",
  props: {
    trustooId: {
      type: Number,
      required: true,
    },
    countryCode: {
      type: String,
      default: "NL",
    },
    badge: {
      type: String,
      default: "hidden",
    },
    quote: {
      type: String,
      default: "hidden",
    },
    size: {
      type: String,
      default: "big",
    },
    type: {
      type: String,
      default: "landscape",
    },
    border: {
      type: String,
      default: "hidden",
    },
    theme: {
      type: String,
      default: "dark",
    },
    background: {
      type: String,
      default: "transparent",
    },
    google: {
      type: String,
      default: "default",
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://static.trustoo.nl/widget/widget_v2.js";
    document.body.appendChild(script);
  },
};
</script>

<style lang="less">
.trustoo-widget {
  padding: 10px 0;
}
</style>
